<template>
  <router-link
    class="block-link"
    :to="href"
  >
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: 'BlockLink',
  props: {
    href: String,
  },
};
</script>

<style lang="scss">
.block-link {
  position: absolute;
  right: 0;
  bottom: 0;
  color: color(basic-light);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  z-index: 3;
  background-color: #fff;
  background: rgba(0, 0, 0, 0.48);
  transition-duration: 0.3s;
  backdrop-filter: blur(11px);

  @include screen("sm", max) {
    align-items: center;
  }

  @include wRule(
    font-size,
    (
      xl: 20,
      lg: 18,
      md: 15,
      sm: 30,
    )
  );

  @include wRule(
    width,
    (
      xl: 706,
      lg: 496,
      md: 370,
      sm: 92.96875vw,
    )
  );

  @include wRule(
    min-height,
    (
      xl: pxtovh(320, xl),
      lg: pxtovh(197, lg),
      md: pxtovh(194, md),
      sm: 224,
    )
  );

  @include wRule(
    padding,
    (
      xl: pxtovh(80, xl) pxtovw(120,xl),
      lg: pxtovh(45, lg) pxtovw(75,lg),
      md: pxtovh(51, md) pxtovw(48,md),
      sm: 85 53,
    )
  );

  @include screen("xxl", min) {
    min-height: 322px;
  }

  @media screen and (min-width: 768px) and (max-height: 735px) {
    min-height: 21.4vh;
  }

  @media screen and (min-width: 768px) and (max-height: 680px) {
    min-height: 18vh;
  }

  @media screen and (min-width: 1434px) and (max-height: 1040px) {
    min-height: 21.4vh;
  }

  span {
    position: relative;
    display: inline-block;
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
  }

  .icon {
    display: block;
    &_arrow {
      @include wRule(
        height,
        (
          xl: 15,
          lg: 11,
          md: 8,
          sm: 18,
        )
      );
      @include wRule(
        width,
        (
          xl: 58,
          lg: 42,
          md: 31,
          sm: 79,
        )
      );

      &:not(:first-child) {
        @include wRule(
          margin-left,
          (
            xl: 35,
            lg: 35,
            md: 20,
            sm: 52,
          )
        );
      }
    }
  }

  &:hover {
    background-color: color(dark);

    span {
      &::before {
        transform: scaleX(1);
      }
    }
  }

  &.is-inview-animation-block-link {
    span {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    .icon {
      transform-origin: 0 50%;
      opacity: 0;
      transform: scaleX(0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }
  }

  &.is-inview-animation-active {
    transition: opacity 0.3s ease, transform 0.3s ease, background 0.3s ease !important;
    transition-delay: 0.5s, 0.5s, 0s !important;

    span {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.3s ease 0.6s, transform 0.3s ease 0.6s;
    }

    .icon {
      opacity: 1;
      transform: scaleX(1);
      transition: opacity 0.3s ease 0.7s, transform 0.3s ease 0.7s;
    }
  }
}
</style>
