<template>
  <ul class="features">
    <template
      v-for="(feature, featureId) in content"
      :key="featureId"
    >
      <li
        class="feature"
        v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: (500 + featureId * 100)}"
      >
        <div class="image">
          <img
            :src="getUrl(feature?.Icon?.url)"
            :alt="feature?.Name"
            :class="'icon'"
          />
        </div>
        <div
          class="text"
          v-html="feature?.Name"
        ></div>
      </li>
    </template>
  </ul>
</template>

<script>
import getUrl from '../../../../../tools/getUrl';

export default {
  name: 'Features',
  props: {
    content: {
      type: Array,
    },
  },
  methods: {
    getUrl,
  },
};
</script>

<style lang="scss" scoped>
.features {
  display: grid;

  @include wRule(grid-template-columns, (
    xl: 1.08fr 1.33fr 1.07fr 1.04fr 1fr,
    lg: 1.58fr 1.57fr 1.48fr 1.15fr 1fr,
    md: 1.41fr 1.25fr 1.02fr 1fr 1fr,
    sm: 1.13fr 1fr,
  ));

  @include wRule(grid-column-gap, (
    xl: 49,
    lg: 49,
    md: 20,
    sm: 55,
  ));

  @include wRule(grid-row-gap, (
    xl: pxtovh(80, xl),
    lg: pxtovh(48, lg),
    md: pxtovh(48, md),
    sm: 85,
  ));

  .feature {
    display: block;
    box-sizing: border-box;
  }

  .image {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @include wRule(
      height,
      (
        xl: 55,
        lg: 44,
        md: 34,
        sm: 55,
      )
    );

    .icon {
      flex-shrink: 0;
      max-height: 100%;
      max-width: 100%;
      height: 100%;
    }
  }

  .text {
    font-weight: 700;
    line-height: 1.2;
    text-wrap: balance;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 25,
      )
    );

    @include wRule(
      max-width,
      (
        xl: 290,
        lg: 170,
        md: none,
        sm: 205,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: pxtovh(36, xl),
          lg: pxtovh(36, lg),
          md: pxtovh(21, md),
          sm: 29,
        )
      );
    }
  }
}

</style>
